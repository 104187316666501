@font-face {
  font-family: "Mona-Sans-M";
  src: url("../fonts/Mona-Sans-Medium.eot");
  src: url("../fonts/Mona-Sans-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Mona-Sans-Medium.woff2") format("woff2"),
    url("../fonts/Mona-Sans-Medium.woff") format("woff"),
    url("../fonts/Mona-Sans-Medium.ttf") format("truetype"),
    url("../fonts/Mona-Sans-Medium.svg#Mona-Sans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans-B";
  src: url("../fonts/Mona-Sans-Bold.eot");
  src: url("../fonts/Mona-Sans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Mona-Sans-Bold.woff2") format("woff2"),
    url("../fonts/Mona-Sans-Bold.woff") format("woff"),
    url("../fonts/Mona-Sans-Bold.ttf") format("truetype"),
    url("../fonts/Mona-Sans-Bold.svg#Mona-Sans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans-S";
  src: url("../fonts/Mona-Sans-SemiBold.eot");
  src: url("../fonts/Mona-Sans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Mona-Sans-SemiBold.woff2") format("woff2"),
    url("../fonts/Mona-Sans-SemiBold.woff") format("woff"),
    url("../fonts/Mona-Sans-SemiBold.ttf") format("truetype"),
    url("../fonts/Mona-Sans-SemiBold.svg#Mona-Sans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans-R";
  src: url("../fonts/Mona-Sans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Mona-Sans-SemiBold.woff2") format("woff2"),
    url("../fonts/Mona-Sans-SemiBold.woff") format("woff"),
    url("../fonts/Mona-Sans-SemiBold.ttf") format("truetype"),
    url("../fonts/Mona-Sans-SemiBold.svg#Mona-Sans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Paytone-One";
  src: url("../fonts/PaytoneOne-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.horizontal-scroll-wrapper {
  display: flex; /* Align card items in a row */
  overflow-x: auto;
  overflow-y: hidden; /* Hide vertical scrollbar */
  padding-bottom: 10px; /* Creates space for the scrollbar */
  margin-bottom: -10px; /* Negatively offsets the added padding */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

.horizontal-scroll-wrapper::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* Show the scrollbar when hovering */
.horizontal-scroll-wrapper:hover {
  scrollbar-width: auto; /* For Firefox */
  -ms-overflow-style: auto; /* For IE and Edge */
}

.horizontal-scroll-wrapper:hover::-webkit-scrollbar {
  display: block; /* Chrome, Safari, and Opera */
}

/* Optional: Style the scrollbar to make it more modern-looking */
.horizontal-scroll-wrapper::-webkit-scrollbar {
  height: 0px;
}

.horizontal-scroll-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.horizontal-scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Last card doesn't need a margin to the right */
.card:last-child {
  margin-right: 5;
}

.truncate-text {
  width: 150px; /* Adjust to match the image width */
  overflow: hidden; /* Hide overflowed text */
  white-space: nowrap; /* Prevent text wrapping */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.card {
  flex-shrink: 0;
  cursor: pointer;
  padding: 12px;
  background-color: #232426;
  border: 1px solid #494949;
  border-radius: 4px;
  margin-right: 6px; /* Adjust this value to get the desired space between cards */
}

.scrollable-container {
  display: flex;
  gap: 6px; /* this replaces your 'gap-[6px]' class */
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* for smooth scrolling on iOS */
}

/* Set the background color */
body {
  background-color: #101010;
}

.InputElement {
  margin: 0 auto;
}

.zindex {
  z-index: 999 !important;
}

.modal-overlay {
  backdrop-filter: blur(10px); /* Adjust blur value as needed */
}

.bottom-audio-player {
  --audio-duration: 0s;
  --audio-play-state: paused;
  --progress-animation: audio-progress-line;
  --progress-content: "";

  align-items: center;
  box-sizing: border-box;
  grid-template-columns: auto max-content minmax(10.5rem, max-content);
  min-width: 0;
  align-items: center;
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1a1a1a;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border-top: 2px solid #1f1f1f;
  width: 100%;
  justify-content: space-between;
  padding: 0 1.25rem;
  height: 66px;

  & > div:first-child {
    flex: 0 0 auto;
  }

  & > div:nth-child(2) {
    flex: 0 1 auto;
  }

  & > div:nth-child(3) {
    flex: 0 0 auto;
  }

  &::before {
    background-color: #00000000;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &::after {
    animation-name: var(--progress-animation);
    animation-duration: var(--audio-duration);
    animation-play-state: paused;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    background-color: rgb(158, 255, 0);
    content: var(--progress-content);
    height: 3px;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: var(--progress-width, 0%);
    z-index: 2;
  }

  &--idle {
    &::after {
      opacity: 0;
      width: var(--progress-width, 0%);
      content: "";
    }
  }

  &--switch {
    &::after {
      animation: none;
      width: 30%;
    }
  }

  &--completed {
    &::after {
      width: 100%;
    }
  }

  &--playing {
    animation-play-state: running;
  }
}

/* Media query to hide the middle child when the window is too small */
@media (max-width: 600px) {
  .parent-container > div:nth-child(2) {
    display: none; /* Hide the middle child when window width is less than 600px */
  }
}

@keyframes audio-progress-line {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.two-divs {
  max-width: 100vw; /* Make sure the element doesn't exceed the viewport width */
  width: 100%; /* Take up 100% of the container width */
  box-sizing: border-box; /* Include padding and borders in the element's width */
  padding: 0 10px; /* Adjust horizontal padding as needed */
}
@media (max-width: 600px) {
  .two-divs {
    padding: 0 5px; /* Decrease padding for smaller screens */
  }
}

/* Apply styles for the custom dropdown class */
.custom-dropdown::-webkit-scrollbar {
  width: 10px;
}
.custom-input {
  outline: none !important; /* Ensure no outline appears */
}
.custom-dropdown::-webkit-scrollbar-track {
  background: #1e1e1e; /* Track background */
  border-radius: 5px;
}

.custom-dropdown::-webkit-scrollbar-thumb {
  background: #3f3f3f; /* Scrollbar thumb color */
  border-radius: 5px;
  border: 2px solid #1e1e1e; /* Space around the thumb */
}

/* Specific styles for highlighted country items */
.country-list .country.highlight {
  background-color: rgba(70, 70, 70, 0.5) !important; /* Transparent grey */
  border-radius: 5px !important; /* Adjust the border radius */
  padding: 7px 9px !important; /* Adjust padding to fit your design */
  cursor: pointer; /* Ensure it's recognizable as clickable */
}

.country .dial-code {
  color: #ffffff !important; /* Replace with your desired bright color */
}

/* Optional: Change the hover style for the highlighted items */
.country-list .country:hover {
  background-color: rgba(
    128,
    128,
    128,
    0.7
  ) !important; /* Darker grey on hover */
}

.custom-dropdown::-webkit-scrollbar-thumb:hover {
  background: #3f3f3f; /* Scrollbar thumb color */
}

.second-div {
  padding-bottom: 70px; /* Adjust based on the height of your audio player */
  display: block;
  width: 100%;
}

.sample-container {
  flex: 2; /* This will make each container take half the width of the parent */
  display: flex;
  align-items: center; /* To align items vertically in the center */
  justify-content: flex-start; /* For sample-container content alignment */
  padding: 0 100px; /* Give some padding inside each container */
}

.volume-button {
  background: none;
  border: none;
  color: white;
  padding: 5px; /* Optional, adjust as needed */
}

.volume-slider-wrapper {
  /* Contains entire volume control slider */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.volume-input {
  width: 100%; /* Full width of its container */
  -webkit-appearance: none; /* Removes default styling */
  appearance: none;
  background: transparent;
}

.volume-input::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 0 !important;
  height: 0 !important;
  background: transparent !important;
}

.volume-input::-moz-range-thumb {
  width: 0 !important;
  height: 0 !important;
  border: none !important;
  background: transparent !important;
}

.volume-slider {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 6px;
  background-color: #525252;
  border-radius: 10px; /* Round the slider by 5px */
}

#volume-level {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  background-color: #1a1a1a;
}

#volume-level::-webkit-slider-runnable-track {
  height: 5px;
  background: #484848;
  overflow: hidden;
  border-radius: 5px;
}

#volume-level::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: white;
  box-shadow: -402px 0 0 400px rgb(255, 255, 255);
}

/* Firefox */
#volume-level::-moz-range-track {
  height: 5px;
  background: #484848;
  border-radius: 5px;
}

/* Firefox - Slider Progress (Left side before the thumb) */
#volume-level::-moz-range-progress {
  background: white; /* Set the color for the filled (left) side */
  border-radius: 5px;
  height: 5px;
  box-shadow: 2px 0 0 0 white;
}

/* Firefox - Slider Thumb */
#volume-level::-moz-range-thumb {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: white;
  border: none; /* Remove default border for Firefox */
}

.album-info {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.album-art {
  width: 50px; /* or the fixed size you want */
  height: 50px; /* make height equal to width for a square */
  display: flex; /* to center the image within the wrapper */
  align-items: center;
  justify-content: center;
  overflow: hidden; /* hides the overflowed part of the image */
}

.album-art img {
  height: 100%; /* sets the image height to the height of the wrapper */
  min-width: 100%; /* ensures minimum width is always the full width of the wrapper */
  object-fit: cover; /* covers the wrapper area without distorting aspect ratio */
  border-radius: 4px;
}

.album-details {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.album-name {
  font-weight: bold;
  margin-bottom: 4px; /* Adds 4 pixels of space below the .album-name */
  color: rgb(209, 209, 209);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px; // Set a fixed width that fits your layout
  /* Additional styling */
}

.album-name {
  width: 500px; // Set a fixed width that fits your layout
  overflow: hidden; // Prevents overflow of text outside the container
  text-overflow: ellipsis; // Adds ellipsis if text is too long to fit
  white-space: nowrap; // Keeps the text on a single line
}

.album-author {
  color: rgb(150, 150, 150);
  /* Additional styling */
}

.audio-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: flex-start; /* Aligns container content to the end */
  flex-shrink: 0;
  width: 100%;
}

.control-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: flex-start; /* Aligns container content to the end */
  height: 32px;
  margin-right: 20px;
}

// .audio-container {
//   display: flex;
//   align-items: center; /* Center items vertically */
//   justify-content: flex-start; /* Align items to the start */
//   width: 100%;
//   max-width: 600px;
//   transform: translateX(200px); /* Adjust this if necessary */
//   box-sizing: border-box;
//   gap: 15px; /* Space between elements */
// }

.control-button {
  padding: 1px 1px;
  background-color: #007bff00;
  color: white;
  border: none;
  cursor: pointer;
  flex: 0 0 auto; /* Do not grow or shrink, use auto width */
  z-index: 10;
  margin-bottom: 0 !important;
}

.audio-player {
  /* Additional class or adjust your existing player CSS */
  flex: 1 1 auto; /* Grow to use available space */
  min-width: 0; /* Prevent flexbox from pushing out beyond max-width */
}

.volume-container {
  justify-content: flex-end; /* Align to the end (right) */
  flex: 0 0 auto; /* Do not grow or shrink, use auto width */
}

// .audio {
//   flex-grow: 1; /* Allows the audio player to take up any remaining space */
// }

.red {
  color: red !important;
}
.img-g {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 36.12%,
    #000000 92.21%
  ) !important;
  opacity: 0.6;
}

.active-sample {
  background-color: #ffffff11; /* Light grey background, or choose your color */
  color: #ffffff; /* Darker text color for better readability */
}

.editor {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editor-section {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}

.player-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.track-current-time {
  color: rgb(190 242 100) !important;
}
.rm-player-progress {
  background-color: rgb(190 242 100) !important;
}

.feed {
  position: absolute;
  right: 0;
  bottom: 100px;
  display: none;
}

.home-bg {
  background: linear-gradient(180deg, #656971 0%, #151515 90.39%);
}

.dots {
  width: 35px;
  height: 20px;
  padding: 0px 8px 0px 8px;
  gap: 2px;
  opacity: 0px;
}

._modal {
  backdrop-filter: blur(15px);
  z-index: 1000;
}

.main-container {
  height: fit-content;
  padding-top: 3rem;
}

#icons-container {
  display: flex;
  position: relative;
  justify-content: center;
}

.icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: -15px;
}

.Toastify__toast-container {
  width: 700px !important;
  text-align: center;
}

.track-info-container {
  width: 500px !important;
}

.user-settings-modal {
  width: 50%; /* Adjust width as needed */
  max-width: 300px; /* Or whatever maximum you prefer */
  min-height: 300px; /* Adjust height as needed */
  margin: 0 auto; /* This will center the modal horizontally */
  /* ... other styles */
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); /* Darken the background */
  backdrop-filter: blur(10px); /* Blur the background */
  z-index: 2000; /* Example z-index for modal */
}

.modal-overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.051); /* Darken the background */
  z-index: 2000; /* Example z-index for modal */
  backdrop-filter: blur(10px); /* Blur the background */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* This will create the blur effect */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0); /* This darkens the background */
}

.account-details-header {
  font-size: 1rem; /* Smaller font size */
  background-color: #9ab91d; /* Green background color */
  color: rgb(0, 0, 0); /* White text color */
  padding: 8px 16px; /* Padding around the text */
  border-top-left-radius: 10px; /* Rounded corners on the top left */
  border-top-right-radius: 10px; /* Rounded corners on the top right */
  margin-bottom: 0; /* No margin below the header */
}

.account-details-form {
  border-radius: 10px; /* Rounded corners of the form */
  overflow: hidden; /* Ensures the children don't overflow */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.058); /* Shadow effect for the form */
}

.account-detail-row:first-child {
  border-top: none; /* No border on top of the first row */
}
.account-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff; /* Black background color for rows */
  background-color: #101010; /* Black background color for rows */
  border-bottom: 0.5px solid #a4a4a42d; /* Border between rows */
  padding: 30px; /* Padding for each row */
  border-right: 0.5px solid #a4a4a42d; /* Border between rows */
  border-left: 0.5px solid #a4a4a42d; /* Border between rows */
  width: 500px;
}

.row-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}
.detail-title {
  font-size: 0.75rem; /* Smaller font size for the title */
  color: #888; /* Light grey color, adjust as needed */
  margin-bottom: 8px; /* Space between title and thumbnail */
}
.account-detail-row:last-child {
  border-bottom: 0.5px solid #a4a4a42d; /* Border between rows */
}

.image-preview {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-thumbnail-preview {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the area without being distorted */
}

.edit-image-modal {
  background: #1a1a1a;
  border-radius: 10px;
  color: white;
  /* Adjust width and height as needed */
  width: 350px; /* Set a new width */

  padding: 20px;
}

.edit-phone-modal {
  background: #1a1a1a;
  border-radius: 10px;
  color: white;
  /* Adjust width and height as needed */
  width: 350px; /* Set a new width */

  padding: 20px;
}

.edit-password-modal {
  background: #1a1a1a;
  border-radius: 10px;
  color: white;
  /* Adjust width and height as needed */
  width: 350px; /* Set a new width */

  padding: 20px;
}

.edit-state-modal {
  background: #1a1a1a;
  border-radius: 10px;
  color: white;
  /* Adjust width and height as needed */
  width: 350px; /* Set a new width */

  padding: 20px;
}

.edit-city-modal {
  background: #1a1a1a;
  border-radius: 10px;
  color: white;
  /* Adjust width and height as needed */
  width: 350px; /* Set a new width */

  padding: 20px;
}

.edit-username-modal {
  background: #1a1a1a;
  border-radius: 10px;
  color: white;
  /* Adjust width and height as needed */
  width: 350px; /* Set a new width */

  padding: 20px;
}

.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4c9aff; /* Color of the edit icon/button */
  font-size: 1.2rem; /* Size of the edit icon/button */
}

.profile-thumbnail {
  width: 150px; /* Width of the thumbnail */
  height: 150px; /* Height of the thumbnail */
  border-radius: 50%; /* Makes the image round */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}
.Toastify__toast-container {
  z-index: 1050;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  color: #858585; /* Replace with your desired color */
}

.modal-content {
  background-color: #333333;
  padding: 0px;
  border-radius: 10px;
  color: white;
  position: relative; /* To position the close button */
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.close-button {
  background: transparent;
  border: none;
  color: rgb(151, 151, 151);
  padding: 10px;
  cursor: pointer;
}

.form-content {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

.input-label {
  display: block;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #555;
  border-radius: 15px;
  background: #222 !important; /* Overriding other styles */
  color: rgb(190, 190, 190);
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #6c0;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

/* Updated CSS */
.row-hover {
  cursor: default;
  transition: background-color 0.3s;
}

/* Updated CSS */
.row-hover .row-play {
  cursor: pointer;
  transition: background-color 0.3s;
}

.row-hover:hover {
  background-color: #282828; /* Darken the row on hover */
}
.row-hover:hover .thumbnail {
  opacity: 0; /* Hide the thumbnail on hover for regular rows */
}

.active-sample:hover .thumbnail {
  opacity: 1 !important; /* Ensure thumbnail stays visible on hover for active samples */
}

.row-hover:hover .play-icon {
  opacity: 1; /* Show the play icon on hover for regular rows */
}

.active-sample:hover .play-icon {
  opacity: 0 !important; /* Hide the play icon on hover for active samples */
}

.thumbnail {
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.play-pause-icon {
  transform: translate(-50%, -50%) scale(2); // Scale up by 50%
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3; // Ensure visibility
}

.play-icon {
  opacity: 0; /* Start with play icon hidden */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2); /* Correct centering */
  z-index: 3; /* Higher z-index to ensure it's above thumbnail */
}

.thumbnail-container {
  position: relative; /* This sets up a new stacking context */
  width: 32px;
  height: 32px;
  margin-right: 32px; /* Instead of adding margin in the class */
}

/* CSS for hiding the play icon on active samples */
.active-sample .play-icon {
  display: none !important; /* Force hide the play button on active samples */
}

th,
td {
  border-bottom: 1px solid #1f1f1f; /* Add your desired border thickness and color */
  max-width: 100%; /* Example of max-width instead of fixed width */
}

/* Responsive text size */
@media (max-width: 768px) {
  th,
  td {
    font-size: smaller; /* Reduce font size on smaller screens */
  }
}

* {
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  .sidebar {
    display: none;
  }
  .logo-text {
    display: none;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto; /* Scroll table inside div when necessary */
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on mobile devices */
}

/* Hide Time, Key, BPM columns on smaller screens */
@media (max-width: 600px) {
  /* Adjust this value based on your needs */
  .time-header,
  .time-data,
  .key-header,
  .key-data,
  .bpm-header,
  .bpm-data {
    display: none;
  }
}

/* PhoneNumberModal.css */
.phone-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.phone-input-container {
  width: 100%;
}

@media (max-width: 768px) {
  .middle-container > * {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .middle-container > * {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .sample-container {
    flex-direction: column; /* Stack the album art and album details vertically */
  }
  .album-art {
    width: 100%; /* Make the album art take up full width */
    height: auto; /* Adjust the height to maintain aspect ratio */
  }
  .album-details {
    margin-top: 10px; /* Add some margin between the album art and album details */
  }
  .control-button {
    margin-bottom: 10px; /* Add some margin between the control buttons */
  }
  .volume-container {
    display: none; /* Hide the volume controls on smaller screens */
  }
}

/* CSS */
@media (max-width: 1200px) {
  .wave-sample {
    display: none;
  }
}

/* CSS */
@media (max-width: 800px) {
  .considering-avatar {
    display: none;
  }
}

/* CSS */
@media (max-width: 900px) {
  .meta-sample {
    display: none;
  }
}

.toggle-container,
.download-link,
.dropdown-container {
  display: inline-block; /* or inline-flex */
  // margin-right: 4px; /* Adjust spacing as needed */
  // vertical-align: middle; /* Aligns vertically */
}

/* CSS */
@media (max-width: 1000px) {
  .toggle-container {
    display: none; /* Hides Toggle at <= 768px */
  }
  .download-link {
    display: none; /* Hides Download link at <= 768px */
  }
}

.dropdown-container {
  z-index: 1000; /* Ensures it stays above other content */
}

@keyframes flickerBorder {
  0%,
  100% {
    border-color: rgba(255, 255, 255, 1);
  }
  50% {
    border-color: rgba(255, 255, 255, 0);
  }
}

.react-joyride__spotlight {
  animation: flickerBorder 1s infinite;
  border: 4px solid white; /* Set the border color and width */
  box-shadow: none; /* Optional: remove any existing box shadow if it interferes */
}

.onboard-body {
  position: fixed;
  left: 50%;
  width: 900px;
  height: 550px;
  top: 50%;
  // transform: translateX(-50%);
  height: 1px; /* Minimal height to keep it unobtrusive */
  width: 1px; /* Minimal width to keep it unobtrusive */
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: #1f1f1f;
  color: white;
  padding: 8px 12px;
  margin-right: 8px;
  border-radius: 20px;
}

.tag button {
  margin-left: 8px;
  color: #ff0000;
  background: none;
  border: none;
  cursor: pointer;
}

.filtering-tool {
  margin-bottom: 10px; /* Space between the dropdown and the message list */
}
.message-item:hover {
  background-color: #333;
}
.message-checkbox {
  margin-right: 10px;
}
.message-avatar {
  width: 40px;
  height: 40px;
  background-color: #444;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.message-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #191919; /* Optional: To add a separator between items */
}
.message-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  background-color: #1e1e1e;
  color: #fff;
  transition: transform 0.3s ease;
  overflow-y: auto;
  position: relative;
}

.message-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.message-name {
  flex: 0 0 auto;
  color: #fff;
  font-size: 14px;
  margin-right: 10px; /* Adjust margin as needed */
}

.message-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff; /* Adjust color as needed */
  font-size: 14px; /* Adjust font size as needed */
  margin-right: 10px; /* Adjust margin as needed */
}

.message-amount {
  flex: 0 0 auto;
  color: #00ff00; /* Adjust color as needed */
  font-size: 14px; /* Adjust font size as needed */
  margin-right: 10px; /* Adjust margin as needed */
}

.message-date {
  flex: 0 0 auto;
  color: #ccc; /* Adjust color as needed */
  font-size: 12px; /* Adjust font size as needed */
  text-align: center;
  margin-right: 10px; /* Adjust margin as needed */
}

.message-priority {
  flex: 0 0 auto;
  color: #ff0000; /* Adjust color as needed */
  font-size: 12px; /* Adjust font size as needed */
}

.message-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #333;
  cursor: pointer;
  color: #fff;
}

.message-item:hover {
  background-color: #333;
}

.message-checkbox {
  margin-right: 10px;
}

.message-avatar {
  width: 40px;
  height: 40px;
  background-color: #444;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.message-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.message-name {
  font-weight: bold;
}

.message-date {
  color: #aaa;
  font-size: 0.9em;
}

.message-priority {
  background-color: #ff0000;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 0.8em;
}

.message-text {
  margin-top: 5px;
  color: #ccc;
}

.message-amount {
  color: #33ff33;
  margin-left: 10px;
}

.message-details {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  background-color: #1e1e1e;
  color: #fff;
  transition: transform 0.3s ease;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
}

.chat-message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.chat-message .message-bubble {
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
}

.chat-message.left .message-bubble {
  background-color: #333;
  align-self: flex-start;
}

.chat-message.right .message-bubble {
  background-color: #1e90ff;
  align-self: flex-end;
}

.message-input-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #1e1e1e;
  border-top: 1px solid #333;
  height: 100vh;
}

.message-timestamp {
  font-size: 0.8em;
  color: #aaa;
  margin-top: 5px;
}

.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #333;
  background-color: #1e1e1e;
}

.message-input input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.small {
  font-size: 10px;
}
.smaller {
  font-size: 8px;
}
.message-input button {
  padding: 10px 20px;
  background-color: #33ff33;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.message-date-line {
  text-align: center;
  color: #aaa;
  margin: 10px 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.audio-1 .rap-container {
  display: flex;
  height: auto !important;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start !important;
  gap: 16px;
  align-self: stretch;
}

.audio-1 .rap-container .rap-controls {
  margin: 0 18px;
}

.audio-1 .rap-pin {
  background-color: #b7b7b7 !important;
  height: 10px !important;
  width: 10px !important;
  top: -4px !important;
}

.audio-2 .rap-container {
  display: flex;
  height: auto !important;
  padding: 12px 16px 12px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.rap-pp-button {
  position: relative;
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background-color: #9eff00;
  width: 32px;
  height: 32px;
}
.rap-pp-button svg {
  width: 16px;
  height: 16px;
}
.audio-1 .rap-volume,
.rap-current-time {
  display: none;
}
.audio-2 .rap-volume-btn svg {
  color: #ffffff !important;
}
.audio-1 .rap-controls {
  color: #ffffff;
}
.audio-1 .rap-total-time {
  text-align: left !important;
  font-size: 10px;
}
.audio-2 .rap-total-time {
  text-align: left !important;
  font-size: 16px;
  color: #b2b2b2;
}
.audio-1 .rap-slider {
  margin-left: 0 !important;
  width: 185px;
  background: #4b4b4b !important;
  height: 2px !important;
}
.audio-2 .rap-slider {
  margin-left: 0 !important;
  width: 35px;
  background: #4b4b4b !important;
  height: 2px !important;
}

.not-found-text {
  font-family: "Paytone-One", sans-serif;
}

// Terms and Conditions Page Styling
.tou-white {
  padding: 0 20px 5px 20px;
  color: white;
  font-size: medium;
  font-weight: 600;
  line-height: 1;
}

.tou-grey {
  padding: 0 20px 0 60px;
  color: grey;
  font-size: medium;
  font-weight: 400;
  line-height: 1;
  list-style-type: disc;
}

// Terms and Conditions Page Circle Animation - START
.rippleContainer {
  width: 100%;
  height: 350px;
  display: grid;
  overflow: hidden;
  position: relative;
}

.growingCircles {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  background-color: transparent;         /* Make the background transparent */
  opacity: 0;
  box-shadow: 0 0 0 2px rgb(190, 242, 100); /* Initial box-shadow same as border width */
  animation: scaleIn 10s infinite linear;
}

.lockImg {
  z-index: 100;
  padding: 50px;
}

.lockImg img {
  width: 50px;
}

@keyframes scaleIn {
  from {
    transform: scale(.1);
    opacity: .5;
  }
  to {
    transform: scale(8.5);
    opacity: 0;
  }
}
// Terms and Conditions Page Circle Animation - END

@tailwind base;
@tailwind components;
@tailwind utilities;
